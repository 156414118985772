@font-face {
  font-family: "icons";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.eot?#iefix") format("embedded-opentype");
}
[class^=new-icon-],
[class*=" new-icon-"] {
  display: inline-block;
  font-family: "icons";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
}

.new-icon-add:before {
  content: "\ea01";
}

.new-icon-check:before {
  content: "\ea02";
}

.new-icon-close:before {
  content: "\ea03";
}

.new-icon-credit-card:before {
  content: "\ea04";
}

.new-icon-filter:before {
  content: "\ea05";
}

.new-icon-instagram:before {
  content: "\ea06";
}

.new-icon-minus:before {
  content: "\ea07";
}

.new-icon-negative:before {
  content: "\ea08";
}

.new-icon-pinterest:before {
  content: "\ea09";
}

.new-icon-tiktok:before {
  content: "\ea0a";
}

.new-icon-trash:before {
  content: "\ea0b";
}

.new-icon-youtube:before {
  content: "\ea0c";
}

/* inter-tight-latin-100-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-100-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-100-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-200-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-200-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.font-inter {
  font-family: "Inter Tight";
}

/* inter-tight-latin-300-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-300-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-300-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* inter-tight-latin-400-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-400-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-400-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* inter-tight-latin-500-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-500-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-500-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* inter-tight-latin-600-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-600-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-600-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* inter-tight-latin-700-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-700-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-700-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* inter-tight-latin-800-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-800-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-800-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* inter-tight-latin-900-normal */
@font-face {
  font-family: "Inter Tight";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-900-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter-tight@latest/latin-900-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  .text-muted {
    @apply tw-text-[#666666];
  }
}
.noUi-handle {
  @apply tw-rounded-full before:tw-hidden after:tw-hidden tw-size-10 !important;
}

.noUi-connect {
  @apply tw-bg-black !important;
}

.noUi-horizontal {
  @apply tw-h-6 !important;
}

.shadow-none {
  box-shadow: none !important;
}

.new-dropdown {
  @apply tw-relative;
}
.new-dropdown-content {
  @apply tw-hidden tw-absolute tw-left-0 tw-top-[100%] tw-shadow tw-p-8 tw-z-[10] tw-bg-white tw-rounded tw-w-[400px];
}
.new-dropdown-content.show {
  @apply tw-block;
}

.new-container-fluid {
  @apply tw-px-8;
}

.container {
  width: 1300px;
  max-width: 100%;
}

.new-card-tag {
  @apply tw-bg-white tw-rounded-lg tw-text-lg tw-px-3 tw-py-1 tw-border tw-border-black;
}

.new-product-card-title {
  @apply tw-font-light;
}
.new-product-card-old-price {
  @apply tw-text-[#AAAAAA] tw-line-through;
}

.new-page,
.new-page a {
  @apply tw-text-[#222222];
}

.new-breadcrumb {
  @apply tw-flex;
}
.new-breadcrumb-item:not(:last-child)::after {
  content: "/";
  margin: 0 10px;
}

.new-footer {
  font-family: "Inter Tight";
  text-align: center;
  color: #222222;
}

.new-footer img {
  max-width: 100%;
  max-height: 100%;
}

.new-footer ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.new-footer hr {
  border-color: #333333;
  opacity: 0.66;
  height: 1px;
}

.new-footer * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new-footer-top {
  background: linear-gradient(180deg, rgba(250, 255, 228, 0) 0%, #faffe4 100%);
  padding: 48px 0;
  position: relative;
}

.new-footer-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: url(../images/footer-signature.svg) no-repeat center center;
}

.new-footer-top > * {
  position: relative;
  z-index: 2;
}

.new-footer-bottom {
  padding: 32px 0;
}

.new-footer-copyright {
  font-size: 12px;
  color: #333333;
}

.new-footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.new-footer-newsletter {
  max-width: 700px;
  margin: 0 auto;
}

.new-footer-newsletter-input {
  border: 1px solid #e0e8bf;
  padding: 16px;
  border-radius: 6px;
}

.new-footer-newsletter-btn {
  background: #000000;
  border-radius: 48px;
  font-weight: 700;
  color: white;
  border: 0;
}

.new-footer-newsletter-form {
  display: flex;
  gap: 16px;
  height: 48px;
}

.new-footer-newsletter-form > * {
  flex: 1;
}

.new-footer-area-title + *,
.new-footer-area-desc + * {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .new-footer-area-title + *,
  .new-footer-area-desc + * {
    margin-top: 10px;
  }
}
.new-footer-bottom {
  background-color: #000000;
  color: white;
}

.new-footer-area-title {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.new-footer input::placeholder {
  color: #eeeeee;
}

.new-footer-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

@media (max-width: 768px) {
  .new-footer-row {
    gap: 16px;
    grid-template-columns: 1fr;
  }
}
.new-footer-spacing {
  padding: 32px 0;
}

@media (max-width: 768px) {
  .new-footer-spacing {
    padding: 16px 0;
  }
}
.new-footer-spacing > * + * {
  margin-top: 24px;
}

.new-footer-menu {
  display: flex;
  flex-direction: column;
}

.new-footer-menu-link {
  color: #cccccc;
  text-decoration: none;
  padding: 8px 0;
  display: inline-block;
}

.new-footer-menu-link:hover {
  color: #fff;
}

.new-footer-socials {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.new-footer-socials-link {
  padding: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-footer-area-clickable .new-footer-area-title svg {
  display: none;
}

@media (max-width: 768px) {
  .new-footer-area-clickable .new-footer-menu {
    transition: all 0.33s;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
  }
  .new-footer-area-clickable .new-footer-area-title svg {
    display: inline-block;
    transition: all 0.33s;
  }
  .new-footer-area-clickable.active .new-footer-menu {
    opacity: 1;
    max-height: 1000px;
  }
  .new-footer-area-clickable.active .new-footer-area-title svg {
    transform: rotate(180deg);
  }
}
.new-page [data-action=show-password] {
  padding: 0 !important;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 0;
  border: 0 !important;
  background-color: transparent !important;
  height: 100%;
}
.new-page [data-action=show-password] i {
  font-size: 16px;
  margin: 0;
  @apply tw-text-[#BBBBBB];
}

.new-nav {
  @apply tw-flex tw-gap-8 tw-list-none tw-p-0;
}
.new-nav-item {
  @apply tw-flex-1 tw-text-center;
}
.new-nav-item a {
  @apply tw-font-semibold !important;
  @apply tw-text-black !important;
}
.new-nav-link {
  @apply tw-transition-all tw-block tw-border tw-py-5 tw-border-[#eee] tw-rounded-lg;
}
.new-nav-link.active {
  @apply tw-bg-black tw-text-white !important;
}

.new-page {
  font-family: "Inter Tight";
}
.new-page .h1,
.new-page .h2,
.new-page .h3,
.new-page .h4,
.new-page .h5,
.new-page .h6,
.new-page h1,
.new-page h2,
.new-page h3,
.new-page h4,
.new-page h5,
.new-page h6 {
  font-family: "Inter Tight";
}

@media (max-width: 991px) {
  #_desktop_top_menu.menu-style2 {
    width: 100%;
    padding: 8px 16px;
  }
}
.blinker {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
.new-media-list {
  @apply tw-p-0;
}
.new-media-list img {
  width: 6rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  margin-right: 10px;
}

.new-alert {
  @apply tw-bg-[#FAFFE4] tw-border-[#000] tw-border tw-px-8 tw-py-6 tw-rounded-lg !important;
}

.new-page {
  @apply tw-leading-[1.5];
}

.btn-dark {
  background-color: black !important;
  color: white !important;
}

[input-qty] input {
  outline: 0;
}
[input-qty] input:focus {
  outline: 0;
}
[input-qty] button {
  outline: 0;
}

.new-cart-panel-item {
  @apply tw-flex tw-gap-6 tw-w-full;
}
.new-cart-panel-item-image {
  @apply tw-size-32 tw-rounded tw-overflow-hidden;
}
.new-cart-panel-item-content {
  @apply tw-flex-1;
}

.onepage-checkout-row {
  min-height: 100%;
}
@media (min-width: 992px) {
  .onepage-checkout-row {
    display: flex;
  }
}
.onepage-checkout-content, .onepage-checkout-sidebar {
  padding: 32px;
  flex: 1;
}
@media (min-width: 992px) {
  .onepage-checkout-content {
    width: 60%;
  }
  .onepage-checkout-content-wrapper {
    width: 700px;
    max-width: 100%;
    margin-left: auto;
  }
}
.onepage-checkout-sidebar {
  background-color: #f7f7f7;
  border-left: 1px solid #dfdfdf;
}
@media (min-width: 992px) {
  .onepage-checkout-sidebar {
    width: 40%;
  }
  .onepage-checkout-sidebar-wrapper {
    width: 500px;
    margin-right: auto;
    max-width: 100%;
  }
}

.new-container {
  min-height: inherit !important;
}

.new-page .custom-checbox {
  font-size: 1.3rem;
}

.onepage-checkout-header {
  border-bottom: 1px solid #dfdfdf;
}
.onepage-checkout-header .logo {
  margin: 0 auto;
  display: block;
}
.onepage-checkout-header .logo img {
  height: 50px;
}

.btn-pill {
  @apply tw-rounded-[48px] tw-min-w-[90px];
}

.new-form-group .radio-inline {
  margin-right: 30px;
  font-size: 1.2rem;
  @apply tw-gap-4;
}

.new-form-group .radio-inline input[type*=radio] {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.checkout-new-nav {
  @apply md:tw-border-none tw-border-b md:tw-pb-0 tw-pb-8 tw-border-[#EEEEEE];
}

.stepper {
  @apply tw-flex tw-justify-between tw-relative;
}
.stepper::before {
  content: "";
  @apply tw-h-[2px] tw-bg-[#888888] tw-w-full tw-absolute tw-top-1/2 tw-left-0;
}
.stepper-item {
  @apply tw-size-10 tw-flex tw-z-[10] tw-items-center tw-justify-center tw-bg-[#888888] tw-rounded-full tw-text-white tw-text-[11px];
}
.stepper-item.active {
  @apply tw-bg-black;
}

@media (max-width: 767px) {
  .new-checkout-step-card {
    @apply tw-border-none tw-p-0 !important;
  }
}

.new-card {
  @apply tw-border tw-border-[#eee] tw-rounded-lg tw-p-8;
}
.new-card-lg {
  @apply tw-p-12;
}
.new-card-title {
  @apply tw-font-semibold tw-text-2xl;
}

.text-md {
  font-size: 16px;
}

.text-xl {
  font-size: 18px;
}

.text-2xl {
  font-size: 20px;
}

.font-medium {
  font-weight: 500;
}

.info-line {
  @apply tw-flex tw-py-4 md:tw-flex-row tw-flex-col;
}
.info-line:not(:last-child) {
  @apply tw-border-b;
}
.info-line-label {
  @apply md:tw-w-72;
}
.info-line-desc {
  @apply tw-flex-1 text-muted;
}

.text-xs {
  font-size: 12px;
}

.new-custom-checkbox input[type=checkbox] + span {
  @apply tw-mr-4;
}

.new-custom-checkbox label,
.new-custom-radio label {
  font-size: 1.25rem;
  @apply tw-flex-1 tw-flex tw-items-center tw-font-normal tw-leading-[20px] tw-gap-4;
}
.new-custom-checkbox input,
.new-custom-radio input {
  @apply tw-hidden;
}
.new-custom-checkbox input + span,
.new-custom-radio input + span {
  @apply tw-bg-none tw-border tw-items-center tw-justify-center tw-rounded-sm tw-size-8 tw-flex !important;
  @apply tw-border-[#222222] !important;
}
.new-custom-checkbox input + span i,
.new-custom-radio input + span i {
  @apply tw-hidden;
}
.new-custom-checkbox input:checked + span,
.new-custom-radio input:checked + span {
  @apply tw-bg-black;
}
.new-custom-checkbox input:checked + span i,
.new-custom-radio input:checked + span i {
  @apply tw-text-white tw-block;
}

.new-footer {
  border-top: 1px solid #dfdfdf;
}
.new-footer a {
  font-weight: 500;
}

.new-custom-radio input + span {
  @apply tw-rounded-full tw-bg-transparent !important;
}
.new-custom-radio input:checked + span::before {
  content: "";
  @apply tw-size-4;
  border-radius: 50%;
  background-color: black;
}

.new-form-checkbox {
  @apply tw-inline-flex tw-items-center tw-cursor-pointer tw-gap-4;
}

.new-form-switch {
  @apply tw-relative tw-w-[4rem] tw-h-9 tw-bg-gray-200 tw-rounded-full peer-checked:after:tw-translate-x-full rtl:peer-checked:after:tw--translate-x-full peer-checked:after:tw-border-black after:tw-content-[""] after:tw-absolute after:tw-top-[4px] after:tw-start-[4px] after:tw-bg-white after:tw-border-gray-500 after:tw-shadow after:tw-rounded-full after:tw-h-6 after:tw-w-6 after:tw-transition-all peer-checked:after:tw-bg-black;
}

@layer base {
  .new-btn {
    @apply tw-px-8 tw-py-5;
    font-size: 13px;
  }
  .new-btn-fixed {
    @apply tw-min-w-[150px];
  }
  .new-btn i.material-icons {
    font-size: inherit;
  }
  .new-btn-dark {
    @apply tw-bg-black tw-text-white;
  }
  .new-btn-light {
    @apply tw-bg-white tw-text-black tw-border;
  }
}
.new-btn.btn-secondary {
  @apply tw-border-[#222];
}

.new-btn-sm {
  @apply tw-px-3 tw-py-2 tw-text-lg;
}

.new-btn-xl {
  @apply tw-py-6;
}
.new-btn-xl.new-btn-fixed {
  min-width: 150px;
}

[data-target="#new-cart-summary-product-list"].collapsed .new-icon-add {
  display: block;
}
[data-target="#new-cart-summary-product-list"].collapsed .new-icon-negative {
  display: none;
}
[data-target="#new-cart-summary-product-list"]:not(.collapsed) .new-icon-add {
  display: none;
}

.new-form-header {
  @apply tw-px-6;
}
.new-form label.error {
  @apply tw-text-[#BBBBBB] tw-text-[11px] tw-px-4 tw-mt-2 tw-leading-[1.5] tw-font-light;
}
.new-form-control {
  @apply tw-px-5 tw-py-3.5 tw-font-light tw-border-[#eee] tw-border tw-rounded-lg tw-w-full;
}