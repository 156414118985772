@font-face {
    font-family: 'icons';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/icons.eot');
    src: url('../fonts/icons.woff') format('woff'),
         url('../fonts/icons.ttf') format('truetype'),
         url('../fonts/icons.eot?#iefix') format('embedded-opentype');
}
  
[class^="new-icon-"],
[class*=" new-icon-"] {
    display: inline-block;
    font-family: 'icons';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-transform: none;
}

$icons: (
    add: '\EA01',
    check: '\EA02',
    close: '\EA03',
    credit-card: '\EA04',
    filter: '\EA05',
    instagram: '\EA06',
    minus: '\EA07',
    negative: '\EA08',
    pinterest: '\EA09',
    tiktok: '\EA0A',
    trash: '\EA0B',
    youtube: '\EA0C'
);

@each $name, $icon in $icons {
    .new-icon-#{$name}:before {
        content: $icon;
    }
}