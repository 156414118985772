.new-footer {
  font-family: "Inter Tight";
  text-align: center;
  color: #222222;
}

.new-footer img {
  max-width: 100%;
  max-height: 100%;
}

.new-footer ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.new-footer hr {
  border-color: #333333;
  opacity: 0.66;
  height: 1px;
}

.new-footer * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new-footer-top {
  background: linear-gradient(180deg, rgba(250, 255, 228, 0) 0%, #faffe4 100%);
  padding: 48px 0;
  position: relative;
}

.new-footer-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: url(../images/footer-signature.svg) no-repeat center center;
}

.new-footer-top > * {
  position: relative;
  z-index: 2;
}

.new-footer-bottom {
  padding: 32px 0;
}

.new-footer-copyright {
  font-size: 12px;
  color: #333333;
}

.new-footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.new-footer-newsletter {
  max-width: 700px;
  margin: 0 auto;
}

.new-footer-newsletter-input {
  border: 1px solid #e0e8bf;
  padding: 16px;
  border-radius: 6px;
}

.new-footer-newsletter-btn {
  background: #000000;
  border-radius: 48px;
  font-weight: 700;
  color: white;
  border: 0;
}

.new-footer-newsletter-form {
  display: flex;
  gap: 16px;
  height: 48px;
}

.new-footer-newsletter-form > * {
  flex: 1;
}

.new-footer-area-title + *,
.new-footer-area-desc + * {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .new-footer-area-title + *,
  .new-footer-area-desc + * {
    margin-top: 10px;
  }
}

.new-footer-bottom {
  background-color: #000000;
  color: white;
}

.new-footer-area-title {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.new-footer input::placeholder {
  color: #eeeeee;
}

.new-footer-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

@media (max-width: 768px) {
  .new-footer-row {
    gap: 16px;
    grid-template-columns: 1fr;
  }
}

.new-footer-spacing {
  padding: 32px 0;
}

@media (max-width: 768px) {
  .new-footer-spacing {
    padding: 16px 0;
  }
}

.new-footer-spacing > * + * {
  margin-top: 24px;
}

.new-footer-menu {
  display: flex;
  flex-direction: column;
}

.new-footer-menu-link {
  color: #cccccc;
  text-decoration: none;
  padding: 8px 0;
  display: inline-block;
}

.new-footer-menu-link:hover {
  color: #fff;
}

.new-footer-socials {
  display: flex;
  gap: 16px;
  justify-content: center;
}
.new-footer-socials-link {
  padding: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-footer-area-clickable .new-footer-area-title svg {
  display: none;
}

@media (max-width: 768px) {
  .new-footer-area-clickable .new-footer-menu {
    transition: all 0.33s;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
  }

  .new-footer-area-clickable .new-footer-area-title svg {
    display: inline-block;
    transition: all 0.33s;
  }

  .new-footer-area-clickable.active .new-footer-menu {
    opacity: 1;
    max-height: 1000px;
  }

  .new-footer-area-clickable.active .new-footer-area-title svg {
    transform: rotate(180deg);
  }
}
